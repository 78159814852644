import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Modal from "react-modal"

import * as page2Style from "./page-2.module.css"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    color: "#111",
  },
}

const SecondPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const texts = edges.map(({ node }) => (
    <div>
      <p>id: {node.id}</p>
      <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
    </div>
  ))

  return (
    <Layout>
      <SEO title="Page two" />

      <h1>Hi from the second page</h1>
      <p>Welcome to page 2. We don't know how you got here...</p>
      <button onClick={openModal}>Open box</button>
      <Link to="/">Go back to the homepage</Link>

      <div className={page2Style.boxStyles}></div>

      <div>{texts}</div>

      <Modal isOpen={modalIsOpen} style={customStyles}>
        <p>Hi! You clicked a button.</p>
        <button onClick={closeModal}>close</button>
      </Modal>
    </Layout>
  )
}

export default SecondPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { collection: { eq: "characters" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          html
        }
      }
    }
  }
`
